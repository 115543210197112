import React, { useState } from 'react'
import { useEffect } from 'react';
import { ClosedEye } from './svg/ClosedEye';
import { Eye } from './svg/Eye';

export const EXPRESIONES_INPUTS = {
    PASSWORD: {
        exp: /^.{4,12}$/, // 4 a 12 digitos.
        errorMsg: "La contraseñas debe tener por lo menos 4 digitos."
    },
    EMAIL: {
        exp: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
        errorMsg: "El email ingresado no es valido"
    },
}

export const Input = ({
    name,
    required,
    inputsForm,
    setInputsForm,
    formError,
    setFormError = () => { },
    validated,
    placeholder,
    type,
    expReg,
    sameValueThat,
    noShowSuccesState,
    disabled,
    onChangeValidation,
    backgroundColorDark,
    rows,
    inputStyle = {},
    containerStyle = {},
}) => {

    const [error, setError] = useState();
    const [revealPass, setRevealPass] = useState()

    const handleChange = (evento) => {
        setFormError(st => {
            const newSt = { ...st }
            delete newSt[name]
            return newSt
        })
        setError(null)

        if (onChangeValidation) { if (!onChangeValidation(evento)) return }

        setInputsForm(st => {
            return { ...st, [name]: evento.target.value }
        })
        if (required) {
            if (!evento.target.value) {
                setError("El campo es requerido")
                setFormError(st => { return { ...st, [name]: "El campo es requerido" } })
            }
            // else {
            //     setError("");
            //     setFormError(st => {
            //         const newSt = { ...st }
            //         delete newSt[name]
            //         return newSt
            //     })
            // }
        }

        if (expReg) {
            if (expReg.exp.test(evento.target.value)) {
                // setError("");
                // setFormError(st => {
                //     const newSt = { ...st }
                //     delete newSt[name]
                //     return newSt
                // })
            } else {
                setError(expReg.errorMsg)
                setFormError(st => { return { ...st, [name]: expReg.errorMsg } })
            }
        }
    }

    useEffect(() => {
        if (sameValueThat == undefined) return;

        if (!sameValueThat || sameValueThat != inputsForm[name]) {
            setError("Ambas contraseñas deben coincidir")
            setFormError(st => { return { ...st, [name]: "Ambas contraseñas deben coincidir" } })
        } else {
            setError("");
            setFormError(st => {
                const newSt = { ...st }
                delete newSt[name]
                return newSt
            })
        }
    }, [sameValueThat, inputsForm[name]])

    useEffect(() => {
        if (formError && formError[name]) {
            setError(formError[name])
        }
    }, [formError])

    useEffect(() => {
        if (required) {
            if (!inputsForm[name]) {
                setError("El campo es requerido")
                setFormError(st => { return { ...st, [name]: "El campo es requerido" } })
            }
        }
    }, [])

    return (
        <div className={`formLogin__input-comp ${disabled ? "input-disabled" : ""} ${validated ? (error ? "input-error" : noShowSuccesState ? "" : "input-success") : ""}`}
            style={{ ...containerStyle, width: "100%" }}>
            <div className="formLogin__input-container">
                {type == "textarea" ?
                    <textarea
                        name={name}
                        rows={rows}
                        placeholder={placeholder ?? name}
                        maxLength='1000'
                        minLength='100'
                        onChange={handleChange}
                        value={inputsForm[name]}
                        disabled={disabled}
                        style={{ backgroundColor: backgroundColorDark ? "#f3f3f3" : "" }}
                    >
                    </textarea>
                    :
                    <input
                        className="formLogin__input"
                        name={name}
                        autoComplete="none"
                        placeholder={placeholder ?? name}
                        type={
                            type ?
                                type == "password" ?
                                    revealPass == true ?
                                        "text"
                                        :
                                        "password"
                                    :
                                    type == "time"
                                        ?
                                        "time"
                                        :
                                        type == "date" ?
                                            "date"
                                            :
                                            "text"
                                :
                                "text"}
                        onChange={handleChange}
                        value={inputsForm[name]}
                        disabled={disabled}
                        style={{
                            ...inputStyle,
                            backgroundColor: backgroundColorDark ? "#f3f3f3" : "",
                            height: type == "time" ? "43px" : "auto",
                        }}
                    >
                    </input>
                }

                {type == "password" &&
                    <div
                        className="input-toggle-visibility-container"
                        onPointerDown={() => { if (!disabled) setRevealPass(true) }}
                        onPointerUp={() => { if (!disabled) setRevealPass(false) }}
                        onPointerLeave={() => { if (!disabled) setRevealPass(false) }}
                    >
                        {!revealPass ?
                            <Eye size={"1.8rem"} disabled={disabled} />
                            :
                            <ClosedEye size={"1.8rem"} />
                        }
                    </div>
                }

            </div>
            {validated && error && error.trim() && <p className="formLogin__input-error-text">{error}</p>}
        </div >
    )
}
