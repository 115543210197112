import React, { useEffect, useState } from 'react'
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { Input } from '../../components/Input';
import { Spinner } from '../../components/Spinner';
import { APP_ROUTES } from '../../constants';
import { authService } from '../../services/auth';
import { appDataActions } from '../../store/appData/appDataActions';

const CREDENDIALES_HARDCODE = {
    ADMIN: {
        email: "admin@gmail.com",
        password: "figure",
    },
    PROFESIONAL: {
        email: "profesional@gmail.com",
        password: "figure",
    },
    CLIENTE: {
        email: "cliente@gmail.com",
        password: "figure",
    },
}

export const FormLogin = ({ noDelayEntranceRef, returnPath }) => {
    const initialInputsValues = {
        email: "",
        password: "",
    }

    const [inputsForm, setInputsForm] = useState(initialInputsValues)
    const [formError, setFormError] = useState({});
    const [validated, setValidate] = useState(false);

    const [fetchError, setFetchError] = useState();
    const [isFetching, setIsFetching] = useState();

    const isMountedSignalRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClickCrearCuenta = () => {
        noDelayEntranceRef.current = true;
        navigate(APP_ROUTES.CREAR_CUENTA, { state: { returnPath } });
    }

    const handleIngresar = async (inputsForm) => {
        setValidate(true)

        if (!(Object.keys(formError).length > 0)) {
            setFetchError("")
            setIsFetching(true)

            try {
                await authService.loginAccount(inputsForm)
                navigate(returnPath ?? APP_ROUTES.HOME)
                dispatch(appDataActions.hideAlertNotification())
            }
            catch (err) {
                setFetchError("El email o contraseña son incorrectos")
            }
            finally {
                setIsFetching(false)
            }
        }
    }

    const handleKeyDown = (evento) => {
        if (evento.target.tagName == "INPUT" && evento.key === "Enter") {
            if (evento) evento.preventDefault();
            handleIngresar(inputsForm);
        }
    }

    const handleLoginRapido = ({ email, password }) => {
        setInputsForm({ email, password })
        handleIngresar({ email, password })
    }


    useEffect(() => {
        setValidate(false);
    }, [inputsForm])

    useEffect(() => {
        const abortController = new AbortController();
        isMountedSignalRef.current = abortController.signal;
        return () => abortController.abort();
    }, [])

    return (
        <div className="formLogin-container initialOpacity leftEntranceOpacity" style={noDelayEntranceRef.current ? { animationDelay: "150ms" } : { animationDelay: "750ms" }}>
            <h1 className="formLogin__title">Iniciar sesion</h1>

            {fetchError &&
                <div className="alert alert-danger" role="alert">{fetchError}</div>
            }

            <form className="formLogin__form" autoComplete="off" onKeyDown={handleKeyDown}>
                <Input
                    name="email"
                    required
                    placeholder="Email"
                    inputsForm={inputsForm}
                    setInputsForm={setInputsForm}
                    setFormError={setFormError}
                    validated={validated}
                    noShowSuccesState={true}
                    disabled={isFetching}
                />
                <Input
                    name="password"
                    required
                    inputsForm={inputsForm}
                    setInputsForm={setInputsForm}
                    setFormError={setFormError}
                    validated={validated}
                    noShowSuccesState={true}
                    placeholder="Contraseña"
                    type="password"
                    disabled={isFetching}
                />
                <Link className="btn-anchor btn-anchor-secondary btn-anchor-sm" to={APP_ROUTES.RECUPERAR}>Olvidaste tu contraseña?</Link>
                <div className="formLogin__buttons-container">
                    <button className="btn-anchor btn-anchor-primary" onClick={handleClickCrearCuenta}>Crear cuenta</button>
                    <button className="btn-primary btn-with-icon" onClick={handleIngresar} disabled={isFetching}>
                        {isFetching ?
                            <><Spinner size="20"></Spinner>Ingresando</>
                            : "Ingresar"}
                    </button>
                </div>
            </form>
            <div>
                <h3 className="gray" style={{ fontSize:"1.6rem", marginTop: "3rem", marginBottom: "0rem" }}>
                    DEVS: Panel logeo rapido - Ingresar como:
                </h3>
                <div style={{ display: "flex", gap: "1rem" }}>
                    <button
                        className="btn-primary btn-with-icon"
                        disabled={isFetching}
                        width="auto"
                        size={"md"}
                        style={{ marginTop: "1rem" }}
                        onClick={() => handleLoginRapido(CREDENDIALES_HARDCODE.ADMIN)}
                    >
                        Admin
                    </button>
                    <button
                        className="btn-primary btn-with-icon"
                        disabled={isFetching}
                        width="auto"
                        size={"md"}
                        style={{ marginTop: "1rem" }}

                        onClick={() => handleLoginRapido(CREDENDIALES_HARDCODE.CLIENTE)}
                    >
                        Cliente
                    </button>
                    <button
                        className="btn-primary btn-with-icon"
                        disabled={isFetching}
                        width="auto"
                        size={"md"}
                        style={{ marginTop: "1rem" }}

                        onClick={() => handleLoginRapido(CREDENDIALES_HARDCODE.PROFESIONAL)}
                    >
                        Profesional
                    </button>
                </div>
            </div>

        </div>
    )
}
